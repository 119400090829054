<template>
  <div v-if="item" class="card" :class="{ card__media: media === 'default' }">
    <router-link :to="{ name: 'newsItem', params: { id: item.id } }" class="card__img">
      <ImgComponent :img="item.img" />
      <div v-if="media === 'default'" class="card__info">
        <div class="card__date">
          <span v-if="item.published_at" class="card__date-day">{{ item.published_at | day }}</span>
          <span v-if="item.published_at" class="card__date-year">{{ item.published_at | monthYear }}</span>
        </div>
        <div v-if="item.images && item.images.length" class="card__counter">
          <IconComponent name="fullscreen-fill" />
          <span>{{ item.images.length }}</span>
        </div>
      </div>
      <div v-else-if="media === 'center'" class="card__info-center">
        <span>когда</span>
        <span>{{ item.planed_at | mediaDate }}</span>
      </div>
    </router-link>
    <div class="card__content">
      <span v-if="item.title" class="card__description">
        {{ item.title }}
      </span>
      <router-link :to="{ name: 'newsItem', params: { id: item.id } }" class="btn card__btn">
        <span>Подробнее</span>
        <IconComponent name="right-arrow" />
      </router-link>
    </div>
  </div>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
import IconComponent from "components/IconComponent.vue";
export default {
  name: "CardComponent",
  props: {
    media: {
      type: String,
      default: () => "default",
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ImgComponent,
    IconComponent,
  },
};
</script>

<style lang="stylus">
.card {
  display flex
  flex-direction column
  //cursor pointer
  height 100%
  min-height 412px
  //gap 15px
  //align-items flex-start
  //background var(--white)
  +below(700px) {
    min-width 240px
    min-height 350px
  }

  &__media ^[0]__date {
    background var(--white-o7);
  }

  &__img {
    position relative
    width 100%
    height: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    +below(700px) {
      min-height 180px
    }

    img {
      width 100%
      height 100%
      +below(700px) {
        min-height 180px
      }
    }
  }

  &__date {
    display flex
    flex-direction column
    align-items center
    padding 7px 9px
    background: var(--white-o7);
  }

  &__info {
    display flex
    flex-direction column
    position absolute
    top 12.5px
    left 12.5px
    z-index 1
    gap 10px
  }

  &__info-center {
    display flex
    margin 0 auto
    flex-direction column
    justify-content center
    align-items center
    background: var(--white-o7);
    padding 15px
    position absolute
    top: 30%;
    //left: 30%;

    span:nth-child(1) {
      font-size: 0.625em
      font-weight: 400;
    }

    span {
      font-size: 1rem
      font-style: normal;
      font-weight: 700;
    }
  }

  &__date-day {
    font-weight 800
    font-size 1.375em
    line-height 27px
  }

  &__date-year {
    font-size 0.75em
    line-height 7px
  }

  &__counter {
    font-weight 500
    font-size 0.625em
    line-height 15px
    background var(--white-o7)
    display flex
    justify-content center
    align-items center
    gap 3px
    //width: 40px;
    height: 25px;

    .icon {
      width 12px
      height 12px
    }
  }

  &__content {
    display flex
    flex-direction column
    justify-content space-between
    align-items flex-start
    padding 15px
    background var(--white)
    height 100%
    +below(700px) {
      gap 30px
    }
  }

  &__description {
    font-weight 700
  }

  &__img {
    transition var(--transition)

    &:hover {
      transform translate(12px, -12px)
      box-shadow: -12px 12px 0px 0px rgba(0, 0, 0, 1);
    }
  }

  &__btn {
    font-size 0.875rem
    line-height 22px
    color var(--main)

    .icon svg {
      path {
        fill var(--main)
      }
    }
  }
}
</style>
